import React from "react"
import {Link} from "gatsby"
export default () => 
<ul>
    <li>
        <Link to="/">Home</Link>
    </li>
    <li>
        <Link to="/news">News</Link>
    </li>
</ul>
